
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { awaitStudentLoaded } from "../store/index";
import { Student } from "@/entities/student";
import firebase from "firebase/app";
import { schoolCollectionKey } from "@/entities/school";
import {
  customPropertyCollectionKey,
  convertToCustomProperty
} from "@/entities/custom_property";

@Options({
  components: {}
})
export default class OriginalInfo extends Vue {
  student: Student | null = null;
  schoolDocId = "";
  classroomDocId = "";
  originalInfos: string[] = [];
  customProperties: { title: string; value: string | number }[] = [];
  loading = false;

  urlChecker(url: string | number): boolean {
    if (typeof url === "number") return false;
    const jpUrlPattern = /https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g;
    return jpUrlPattern.test(url);
  }

  async created(): Promise<void> {
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "オリジナル情報取得中...");
    this.loading = true;

    const student = await awaitStudentLoaded(store);

    this.student = student;
    this.schoolDocId = store.state.schoolDocId;
    this.classroomDocId = store.state.classroomDocId;
    this.originalInfos = (this.$route.params.originalInfoIds as string[]) ?? []; //IDが渡ってくる前提

    if (!student || !this.schoolDocId || !this.classroomDocId) {
      alert("オリジナル情報の取得に失敗しました。");
      return;
    }

    try {
      const db = firebase.firestore();
      const customPropertiesSnapshot = await db
        .collection(schoolCollectionKey)
        .doc(this.schoolDocId)
        .collection(customPropertyCollectionKey)
        .get();
      if (!customPropertiesSnapshot || customPropertiesSnapshot.empty) {
        alert("スクール上にオリジナル情報が見つかりません。");
        this.loading = false;
        store.commit("SET_LOADING", false);
        return;
      }
      const customProperties = customPropertiesSnapshot.docs
        .map(p => convertToCustomProperty(p.data(), p.id, p.ref))
        .filter(p => this.originalInfos.includes(p.id));
      if (customProperties.length === 0) {
        alert("該当のオリジナル情報が見つかりません");
        this.loading = false;
        store.commit("SET_LOADING", false);
        return;
      }

      const { properties } = this.student.data;
      if (!properties) {
        alert("登録されたオリジナル情報が見つかりません");
        this.loading = false;
        store.commit("SET_LOADING", false);
        return;
      }

      customProperties.forEach(p => {
        const found = properties.find(_p => _p.id === p.id);
        if (found) {
          this.customProperties.push({
            title: p.data.title,
            value: found.value
          });
        }
      });
    } catch (e) {
      alert("オリジナル情報の取得に失敗しました。");
      console.error(e);
    } finally {
      this.loading = false;
      store.commit("SET_LOADING", false);
    }
  }
}
